if (document.getElementById('plz-form')) {
	const form = document.getElementById('plz-form')
	form.addEventListener('submit', e => validatePlz(e))
	
	const validatePlz = (e) => {
		e.preventDefault();
		const formular = document.getElementById('plz-form')
		const plz = formular.plz.value

		if (isNaN(plz) || plz.length !== 5) {
			document.getElementById("error").innerHTML = 'Geben Sie bitte eine gültige Postleitzahl ein.';
		} else {
			formular.submit()
		}
		
	}
}

$(document).ready(function(){
	$(function() {
		$('a[href*="#"]:not([href="#"])').click(function() {
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.length) {
					$('html, body').animate({
						scrollTop: target.offset().top
					}, 1000);
					return false;
				}
			}
		});
	});

});






